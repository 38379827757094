import React, { ReactElement, useContext, useState } from 'react';
import { Breadcrumb as AntdBreadcrumb, Button, Menu } from 'antd';
import NewLanguageDialog from './NewLanguageDialog';
import { Panel } from '../Backend/Models/Panel';
import styled from 'styled-components';
import { DIContext } from '../Dependencies';
import {TemplateCollection} from "../Backend/Models/ViewModel";

const StyledBreadCrumb = styled(AntdBreadcrumb)`
    margin-bottom: 1em;
    padding: 0.5em 1em 0.5em 1em;
    background-color: white
`

export interface BreadcrumbProps {
    panel: Panel,
    supportedLanguages: string[],
    isoLanguage: string,
    onIsoLanguageChanged: (isoLanguage: string) => void;
    className?: string
}

const ADD_NEW_LANGUAGE_KEY = "add_key"

export default function PanelBreadcrumb({ panel, supportedLanguages, isoLanguage, onIsoLanguageChanged, className }: BreadcrumbProps): ReactElement {
    const { currentUser } = useContext(DIContext);

    const [isNewLanguageDialogVisible, setIsNewLanguageDialogVisible] = useState<boolean>(false);

    function isoLanguageMenuClick(e: any): void {
        if (e.key == ADD_NEW_LANGUAGE_KEY)
            setIsNewLanguageDialogVisible(true);
        else
            onIsoLanguageChanged(e.key);
    }
    const menuItems = supportedLanguages
        .filter(language => language != isoLanguage)
        .map(language => (<Menu.Item key={language}>{language}</Menu.Item>));

    function onIsoLanguageCreated({ isoLanguage }: TemplateCollection): void {
        setIsNewLanguageDialogVisible(false);
        onIsoLanguageChanged(isoLanguage);
    }

    if (currentUser.MultiLanguageSupport) {
        menuItems.push(
            <Menu.Item key={ADD_NEW_LANGUAGE_KEY}>
                <Button
                    size='small'
                    type='primary'>
                    Add language
                </Button>
            </Menu.Item>)
    }

    return (
        <>
            <NewLanguageDialog
                panelGuid={panel.guid}
                isVisible={isNewLanguageDialogVisible}
                onCreate={onIsoLanguageCreated}
                onCancel={() => setIsNewLanguageDialogVisible(false)} />
            <StyledBreadCrumb
                className={className}>
                <AntdBreadcrumb.Item>{panel.name}</AntdBreadcrumb.Item>
                {menuItems.length > 0
                    ? <AntdBreadcrumb.Item
                        overlay={(
                            <Menu onClick={isoLanguageMenuClick}>
                                {menuItems}
                            </Menu>
                        )}>
                        {isoLanguage}
                    </AntdBreadcrumb.Item>
                    : <AntdBreadcrumb.Item>{isoLanguage}</AntdBreadcrumb.Item>}
            </StyledBreadCrumb>
        </>);
}
