import React, { ReactElement, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export interface HtmlEditorProps {
    initialValue?: string,
    disabled: boolean,
    onValueChanged: (value: string) => void,
    height?: number
}

export default function HtmlEditor({ initialValue, disabled, onValueChanged, height }: HtmlEditorProps): ReactElement {
    const [value, setValue] = useState<string>(initialValue ?? '');

    useEffect(() => {
        onValueChanged(value);
    }, [value])

    return (
        <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            value={value}
            init={{
                height: height ?? 500,
                menubar: "format tools table",
                plugins: [
                    "preview searchreplace visualblocks table code"
                ],
                toolbar: "block undo redo | visualblocks | bold underline italic  | alignleft aligncenter alignright alignjustify | bullist numlist | fontselect fontsizeselect formatselect | forecolor backcolor removeformat | code"
            }}
            disabled={disabled}
            onEditorChange={(newValue) => setValue(newValue)}
        />
    );
}
