import React, { ReactElement } from "react";
import { Alert, Button, Space } from "antd";

export default function DefaultTemplateWarning({ visible, onEditClick }: { visible: boolean, onEditClick: () => void }): ReactElement {
    return (
        visible
            ? <Alert
                message="You are currently viewing the deafult template for this language."
                description="Click 'Edit' to enable editing."
                type="warning"
                closable
                action={
                    <Space>
                        <Button
                            size="small"
                            type="ghost"
                            onClick={onEditClick}>
                            Edit
                        </Button>
                    </Space>
                } />
            : <></>
    );
}