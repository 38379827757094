import React, { ReactElement } from "react";
import { Menu } from "antd";

export interface SideMenuItem{
    key: string,
    displayName : string
}

export interface SideMenuProps {
    selectedKey?: string,
    onSelect: ({ key }: { key: string }) => void;
    items: SideMenuItem[];
}

export default function SideMenu({ selectedKey, onSelect, items }: SideMenuProps): ReactElement {
    return (
        <Menu
            selectedKeys={selectedKey ? [selectedKey] : []}
            onSelect={onSelect}>
            {items.map(x => (<Menu.Item key={x.key}>{x.displayName}</Menu.Item>))}
        </Menu>)
}
