import React, { ReactElement } from 'react';
import { Keyword } from '../Backend/Models/Keyword';
import { Collapse, List, Typography } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em 3em 2em 5em;
    width: 40%;
`;

const Key = styled(Typography.Text)`
    width: 35%;
    font-size: small;
    margin-right: 1em;
`;

const Description = styled(Typography.Text)`
    width: 65%;
    font-size: small
`;

export default function Keywords({ keywords }: { keywords: Keyword[] }): ReactElement {
    const mandatoryKeywords = keywords.filter(x => x.isMandatory);
    const optionalKeywords = keywords.filter(x => !x.isMandatory);

    const renderKeyword = (item: Keyword) => (
        <List.Item>
            <Key>{item.key}</Key>
            <Description type="secondary">{item.description}</Description>
        </List.Item>
    );

    return (
        <Container>
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Mandatory keywords" key={1}>
                    <List
                        dataSource={mandatoryKeywords}
                        size="small"
                        renderItem={renderKeyword}
                    />
                </Panel>
            </Collapse>
            <Collapse>
                <Panel header="Optional keywords" key={2}>
                    <List
                        dataSource={optionalKeywords}
                        size="small"
                        renderItem={renderKeyword}
                    />
                </Panel>
            </Collapse>
        </Container>);
}