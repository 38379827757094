import React, { ReactElement } from 'react';
import { Tabs } from 'antd';
import WelcomeTemplateEditor from './WelcomeTemplateEditor'
import { Keyword } from '../Backend/Models/Keyword';
import { TemplateCollection } from '../Backend/Models/ViewModel';
import InvitationTemplateEditor from './InvitationTemplateEditor';

const { TabPane } = Tabs;

export interface TemplateEditorContainerParams {
    panelGuid?: string,
    templates: TemplateCollection,
    welcomeKeywords: Keyword[],
    invitationKeywords: Keyword[],
    onSave?: () => void;
}

export default function TemplateEditorContainer({ panelGuid, templates: templates, welcomeKeywords, invitationKeywords, onSave }: TemplateEditorContainerParams): ReactElement {

    const welcomeTemplate = templates.welcomeTemplate;
    const welcomeReminderTemplate = templates.welcomeReminderTemplate;
    const invitationTemplate = templates.invitationTemplate;
    const invitationReminderTemplate = templates.invitationReminderTemplate;

    return (
        <Tabs defaultActiveKey="1" centered={false}>
            <TabPane tab="Welcome Template" key="1">
                <WelcomeTemplateEditor panelGuid={panelGuid} template={welcomeTemplate} keywords={welcomeKeywords} onSave={onSave}/>
            </TabPane>
            <TabPane tab="Welcome Reminder Template" key="2">
                <WelcomeTemplateEditor panelGuid={panelGuid} template={welcomeReminderTemplate} keywords={welcomeKeywords} onSave={onSave}/>
            </TabPane>
            <TabPane tab="Invitation Template" key="3">
                <InvitationTemplateEditor panelGuid={panelGuid} invitationTemplate={invitationTemplate} invitationReminderTemplate={invitationReminderTemplate} keywords={invitationKeywords} onSave={onSave}/>
            </TabPane>
        </Tabs>
    );
}
