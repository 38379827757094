import { TemplatePreview as TemplatePreview } from "../Models/TemplatePreview";
import { TemplatePreviewRequest as TemplatePreviewRequest } from "../Models/TemplatePreviewRequest";
import { TemplateCollection } from "../Models/ViewModel";
import { Template } from "../Models/Template";
import { TemplateCreateRequest } from "../Models/TemplateCreateRequest"


export class DefaultTemplateEndpoint {
	constructor(private baseUrl: string) { }

    public async Get(isoLangauge: string): Promise<TemplateCollection> {
		const response = await fetch(new URL(`defaulttemplate/${isoLangauge}`, this.baseUrl).href, { credentials: 'include' })
        return await response.json();
    }
	public async Post(request: TemplateCreateRequest) : Promise<TemplateCollection> {
		const response = await fetch(
            new URL(`defaulttemplate/`, this.baseUrl).href,
            {
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify(request)
            });

        const result = await response.json();
        if (response.ok) {
            return result;
        } else {
            return Promise.reject(result.errors);
        }
    }

    public async Put(template: Template): Promise<void> {
        const response = await fetch(
            new URL(`defaulttemplate/`, this.baseUrl).href,
            {
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify(template)
            });

		if (response.ok) {
			return;
		} else {
			const result = await response.json();
			return Promise.reject(result.errors);
		}
    }

	public async Preview(request: TemplatePreviewRequest): Promise<TemplatePreview> {
		const response = await fetch(
			new URL(`defaulttemplate/preview`, this.baseUrl).href,
			{
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(request)
			});
		return await response.json();
	}
}
