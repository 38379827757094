import React, { ReactElement, useContext, useState } from 'react';
import { Divider, message } from 'antd';
import Preview from './Preview';
import { Template } from '../Backend/Models/Template';
import { Keyword } from '../Backend/Models/Keyword';
import { DIContext } from '../Dependencies';
import { FieldData } from 'rc-field-form/es/interface';

import { TemplatePreviewRequest } from '../Backend/Models/TemplatePreviewRequest';
import TemplateForm from './TemplateForm';
import Keywords from './Keywords';
import DefaultTemplateWarning from './DefaultTemplateWarning';
import Title from 'antd/lib/typography/Title';
import { CreateFieldDataFromValidationError } from './Utils';

import { EditorContainer, Flexbox } from './TemplateEditor.styles';

export interface InvitationTemplateEditorParams {
    panelGuid?: string,
    invitationTemplate: Template,
    invitationReminderTemplate: Template,
    keywords: Keyword[],
    onSave?: () => void;
}

export default function InvitationTemplateEditor({ panelGuid, invitationTemplate, invitationReminderTemplate, keywords, onSave }: InvitationTemplateEditorParams): ReactElement {
    const { panelTemplateEndpoint, defaultTemplateEndpoint } = useContext(DIContext);

    const [displayDefaultTemplateWarning, setDisplayDefaultTemplateWarning] = useState<boolean>(invitationTemplate.isReadOnly);
    const [isEditingEnabled, setIsEditingEnabled] = useState<boolean>(!invitationTemplate.isReadOnly);

    const [fieldErrors, setFieldErrors] = useState<FieldData[]>();
    async function handleSubmit(template: Template) {
        try {
            if (panelGuid) {
                await panelTemplateEndpoint.Put(panelGuid, template);
            } else {
                await defaultTemplateEndpoint.Put(template);
            }
            message.success('Save Successful');
            if(onSave)
                onSave();
        } catch (errors) {
            const fieldErrors = CreateFieldDataFromValidationError(errors);

            if (fieldErrors)
                setFieldErrors(fieldErrors)
        }
    }

    const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
    const [previewContent, setPreviewContent] = useState<string>("");


    let currentInvitationBody = invitationTemplate.body;
    function currentInvitationBodyChanged(value: string) {
        currentInvitationBody = value;
    }

    const showPreview = async (previewRequest: TemplatePreviewRequest) => {
        const previewContent = await getPreviewContent(panelGuid, previewRequest)
        setPreviewContent(previewContent);
        setIsPreviewVisible(true);
    }

    const showReminderPreview = async (previewRequest: TemplatePreviewRequest) => {
        previewRequest.body = previewRequest.body + currentInvitationBody;

        const previewContent = await getPreviewContent(panelGuid, previewRequest)
        setPreviewContent(previewContent);
        setIsPreviewVisible(true);
    }

    async function getPreviewContent(panelGuid: string | undefined, previewRequest: TemplatePreviewRequest): Promise<string>{
        let result;
        if (panelGuid) {
            result = await panelTemplateEndpoint.Preview(panelGuid, previewRequest);
        } else {
            result = await defaultTemplateEndpoint.Preview(previewRequest);
        }
        return result.content;
    }

    return (
        <>
            <DefaultTemplateWarning
                visible={displayDefaultTemplateWarning}
                onEditClick={() => {
                    setIsEditingEnabled(true);
                    setDisplayDefaultTemplateWarning(false);
                }} />
            <Flexbox>
                <EditorContainer>
                    <TemplateForm
                        template={invitationTemplate}
                        isEditingEnabled={isEditingEnabled}
                        onSubmit={handleSubmit}
                        fieldErrors={fieldErrors}
                        showPreview={showPreview}
                        onBodyChanged={currentInvitationBodyChanged}
                    />
                    <Divider />
                    <Title level={5}>Invitation Reminder</Title>
                    <TemplateForm
                        template={invitationReminderTemplate}
                        isEditingEnabled={isEditingEnabled}
                        onSubmit={handleSubmit}
                        fieldErrors={fieldErrors}
                        showPreview={showReminderPreview}
                        isInvitationReminder={true}
                    />
                </EditorContainer>
                <Keywords keywords={keywords} />
                <Preview
                    content={previewContent}
                    isModalVisible={isPreviewVisible}
                    toggleModal={() => setIsPreviewVisible(false)} />
            </Flexbox>
        </>
    );
}
