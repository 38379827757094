import { Button } from 'antd'
import React, { ReactElement, useState } from 'react'
import NewLanguageDialog from './NewLanguageDialog'
import {TemplateCollection} from "../Backend/Models/ViewModel";

type NewDefaultTemplateProps = {
    onCreated?: (isoLanguage: TemplateCollection) => void;
}

export default function NewDefaultTemplate({ onCreated }: NewDefaultTemplateProps) : ReactElement {
    const [dialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => setDialogOpen(true)
    const closeDialog = () => setDialogOpen(false)

    function onLanguageCreated(templateCollection: TemplateCollection) {
        setDialogOpen(false);
        if (onCreated) onCreated(templateCollection);
    }

    return (
        <>
            <Button block type='primary' onClick={openDialog}>
                New Default Template
            </Button>
            <NewLanguageDialog
                isVisible={dialogOpen}
                onCreate={onLanguageCreated}
                onCancel={closeDialog} />
        </>
    )
}
