import { FieldData } from 'rc-field-form/es/interface';

// eslint-disable-next-line
export function CreateFieldDataFromValidationError(errors: any): FieldData[] {
	return Object.getOwnPropertyNames(errors)
		.map(e => {
			return {
				name: e,
				errors: errors[e]
			}
		}) as Array<FieldData>;
}
