import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Admin from './Admin';
import reportWebVitals from './reportWebVitals';
import { AppSettings } from './AppSettings';
import appSettings_Dev from './appsettings.dev.json';
import { ConfigureDependencies, DIContext } from './Dependencies';
import { BrowserRouter, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { AuthenticationEndpoint } from './Backend/Endpoints/AuthenticationEndpoint';

async function getAppSettings(): Promise<AppSettings> {
    const resp = await fetch('/appsettings.json')
    return await resp.json();
}

(async () => {
    let appSettings: AppSettings = await getAppSettings();
    if (process.env.NODE_ENV === 'development') {
        appSettings = { ...appSettings, ...appSettings_Dev }
    }

    const authenticationEndpoint = new AuthenticationEndpoint(appSettings.baseUrl);
    const user = await authenticationEndpoint.Authorize();

    ReactDOM.render(
        <React.StrictMode>
            <DIContext.Provider value={ConfigureDependencies(appSettings, user!)}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/admin/:isoLanguage?">
                            <AdminWithParams />
                        </Route>
                        <Route path="/:companyGuid/:panelGuid?/:isoLanguage?">
                            <AppWithParams />
                        </Route>
                        <Route exact path="/">
                            <Redirect to={`/${user!.companyGuid}`} />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </DIContext.Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
})();

function AppWithParams() {
    const { companyGuid, panelGuid, isoLanguage } = useParams<any>();
    return <App companyGuid={companyGuid} panelGuid={panelGuid} isoLanguage={isoLanguage} />;
}

function AdminWithParams(){
    const { isoLanguage } = useParams<any>();
    return <Admin isoLanguage={isoLanguage} />;

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
