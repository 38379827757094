import React, { ReactElement, useContext, useState } from 'react';
import { message } from 'antd';
import Preview from './Preview';
import { Template } from '../Backend/Models/Template';
import { Keyword } from '../Backend/Models/Keyword';
import { DIContext } from '../Dependencies';
import { FieldData } from 'rc-field-form/es/interface';

import { TemplatePreviewRequest } from '../Backend/Models/TemplatePreviewRequest';
import TemplateForm from './TemplateForm';
import Keywords from './Keywords';
import DefaultTemplateWarning from './DefaultTemplateWarning';
import { CreateFieldDataFromValidationError } from './Utils';
import { EditorContainer, Flexbox } from './TemplateEditor.styles';

export interface TemplateEditorProps {
	panelGuid?: string,
	template: Template,
	keywords: Keyword[],
	onSave?: () => void;
}

export default function WelcomeTemplateEditor({ panelGuid, template, keywords, onSave }: TemplateEditorProps): ReactElement {
	const { panelTemplateEndpoint, defaultTemplateEndpoint } = useContext(DIContext);

	const [displayDefaultTemplateWarning, setDisplayDefaultTemplateWarning] = useState<boolean>(template.isReadOnly);
	const [isEditingEnabled, setIsEditingEnabled] = useState<boolean>(!template.isReadOnly);

	const [fieldErrors, setFieldErrors] = useState<FieldData[]>();
	async function handleSubmit(template: Template) {
		try {
			if (panelGuid) {
				await panelTemplateEndpoint.Put(panelGuid, template);
			} else {
				await defaultTemplateEndpoint.Put(template);
			}
			
			message.success('Save Successful');
			
			if(onSave)
				onSave();

		} catch (errors) {
			const fieldErrors = CreateFieldDataFromValidationError(errors);

			if (fieldErrors)
				setFieldErrors(fieldErrors)
		}
	}

	const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
	const [previewContent, setPreviewContent] = useState<string>("");
	const showPreview = async (previewRequest: TemplatePreviewRequest) => {
		let result;
		if (panelGuid) {
			result = await panelTemplateEndpoint.Preview(panelGuid, previewRequest);
		} else {
			result = await defaultTemplateEndpoint.Preview(previewRequest);
		}

		setPreviewContent(result.content);
		setIsPreviewVisible(true);
	}

	return (
		<>
			<DefaultTemplateWarning
				visible={displayDefaultTemplateWarning}
				onEditClick={() => {
					setIsEditingEnabled(true);
					setDisplayDefaultTemplateWarning(false);
				}}
			/>
			<Flexbox>
				<EditorContainer>
					<TemplateForm
						template={template}
						isEditingEnabled={isEditingEnabled}
						onSubmit={handleSubmit}
						fieldErrors={fieldErrors}
						showPreview={showPreview}
					/>
				</EditorContainer>
				<Keywords keywords={keywords} />
				<Preview
					content={previewContent}
					isModalVisible={isPreviewVisible}
					toggleModal={() => setIsPreviewVisible(false)} />
			</Flexbox>
		</>
	);
}
