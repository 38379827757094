import React, { ReactElement, useContext, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { DIContext } from '../Dependencies';
import {TemplateCollection} from "../Backend/Models/ViewModel";

export interface NewLanguageDialogProps {
    panelGuid?: string,
    isVisible: boolean,
    onCancel: () => void,
    onCreate: (templateCollection: TemplateCollection) => void
}

export default function NewLanguageDialog({ panelGuid, isVisible, onCancel, onCreate }: NewLanguageDialogProps): ReactElement {
    const { panelTemplateEndpoint, defaultTemplateEndpoint } = useContext(DIContext);

    const [form] = Form.useForm();

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    function addValidationErrors(validationErrors: Record<string, string[]>) {
        for (const [name, errors] of Object.entries(validationErrors)) {
            form.setFields([{name, errors}])
        }
    }

    async function onSubmit() {
        setConfirmLoading(true);
        const createRequest = await form.validateFields();

        try {
            const result = await (
                panelGuid
                ? panelTemplateEndpoint.Post(panelGuid, createRequest)
                : defaultTemplateEndpoint.Post(createRequest));

            form.resetFields();
            onCreate(result);
        } catch (errors) {
            addValidationErrors(errors as Record<string, string[]>)
        } finally {
            setConfirmLoading(false);
        }

    }

    function handleCancelClicked() {
        form.resetFields();
        onCancel();
    }

    return (
        <Modal
            visible={isVisible}
            onOk={onSubmit}
            okText="Add"
            onCancel={handleCancelClicked}
            confirmLoading={confirmLoading}
            closable={false}>
            <Form
                form={form}
                requiredMark={false}>
                <Form.Item
                    label="Language"
                    name="IsoLanguage"
                    rules={[{ required: true, message: "Please provide a language to add" }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}
