import { TemplatePreview } from "../Models/TemplatePreview";
import { TemplatePreviewRequest } from "../Models/TemplatePreviewRequest";
import { TemplateCollection } from "../Models/ViewModel";
import { Template } from "../Models/Template";
import { TemplateCreateRequest } from "../Models/TemplateCreateRequest";

export class PanelTemplateEndpoint {
    constructor(private baseUrl: string) { }

    public async Put(panelGuid: string, template: Template): Promise<void> {
        const response = await fetch(
            new URL(`panel/${panelGuid}/template/`, this.baseUrl).href,
            {
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify(template)
            });

		if (response.ok) {				
			return;
		} else {
			const result = await response.json();
			return Promise.reject(result.errors);
		}	
    }

	public async Post(panelGuid: string, request: TemplateCreateRequest): Promise<TemplateCollection> {
        const response = await fetch(
            new URL(`panel/${panelGuid}/template/`, this.baseUrl).href,
            {
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify(request)
            });
		
		const result = await response.json();
		if (response.ok) {				
			return result;
		} else {
			return Promise.reject(result.errors);
		}
	}

	public async Preview(panelGuid: string, request: TemplatePreviewRequest): Promise<TemplatePreview> {
		const response = await fetch(
			new URL(`panel/${panelGuid}/template/preview`, this.baseUrl).href,
			{
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(request)
			});
		return await response.json();
	}
}
