import { User } from "./Backend/Models/User"

export class CurrentUser {
    constructor(user: User) {
        this.IsAdmin = user.isAdmin,
        this.MultiLanguageSupport = user.multiLanguageSupport
     }

    public IsAdmin: boolean
    public MultiLanguageSupport: boolean
}
