import { ViewModel } from "../Models/ViewModel";

export class ViewModelEndpoint{
    constructor(private baseUrl: string) { }
    
    public async Get(panelGuid: string): Promise<ViewModel>;
    public async Get() : Promise<ViewModel>;
    public async Get(panelGuid?:string) : Promise<ViewModel>{
        const url = panelGuid 
            ? new URL(`viewmodel/${panelGuid}`, this.baseUrl)
            : new URL('viewmodel', this.baseUrl);

        const response = await fetch(url.href, { credentials: 'include' })
        return await response.json();
    }
}