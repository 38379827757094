import styled from "styled-components";

export const Flexbox = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`

export const EditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width: 750px
`;