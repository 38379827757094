import React, { ReactElement, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { Template } from '../Backend/Models/Template';
import HtmlEditor from './HtmlEditor';
import { TemplatePreviewRequest } from '../Backend/Models/TemplatePreviewRequest';

import styled from 'styled-components';

const EditorForm = styled(Form)`
    margin-top: 1em
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: nowrap
`;

export interface TemplateFormProps {
    template: Template,
    isEditingEnabled: boolean,
    onSubmit: (values: Template) => void,
    fieldErrors?: FieldData[],
    showPreview: (panelTemplatePreviewRequest: TemplatePreviewRequest) => void,
    onBodyChanged?: (body: string) => void;
    isInvitationReminder?: boolean
}

export default function TemplateForm({ template, isEditingEnabled, onSubmit, fieldErrors, showPreview, onBodyChanged, isInvitationReminder }: TemplateFormProps): ReactElement {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [template]);

    useEffect(() => {
        if (fieldErrors)
            form.setFields(fieldErrors);
    }, [fieldErrors]);

    function onSaveClick(values: any) {
        onSubmit(values as Template);
    }

    function onPreviewClick() {
        const previewRequest = {
            mailType: form.getFieldValue("MailType"),
            fromDisplayName: form.getFieldValue("FromDisplayName"),
            fromAddress: form.getFieldValue("FromAddress"),
            replyToDisplayName: form.getFieldValue("ReplyToDisplayName"),
            replyToAddress: form.getFieldValue("ReplyToAddress"),
            subject: form.getFieldValue("Subject"),
            body: form.getFieldValue("Body")
        } as TemplatePreviewRequest;
        showPreview(previewRequest);
    }

    function onHtmlEditorValueChanged(value: string) {
        form.setFieldsValue({ "Body": value });

        if (onBodyChanged)
            onBodyChanged(value);
    }

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };
    const buttonsLayout = {
        wrapperCol: { offset: 19, span: 5 }
    }
    return (
        <EditorForm
            form={form}
            {...layout}
            requiredMark={false}
            name="basic"
            initialValues={{
                Id: template.id,
                MailType: template.mailType,
                IsoLanguage: template.isoLanguage,
                FromDisplayName: template.fromDisplayName,
                FromAddress: template.fromAddress,
                ReplyToDisplayName: template.replyToDisplayName,
                ReplyToAddress: template.replyToAddress,
                Subject: template.subject,
                Body: template.body
            }}
            onFinish={onSaveClick}>
            <Form.Item name="Id" hidden={true}>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="MailType" hidden={true}>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="IsoLanguage" hidden={true}>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item
                label="Sender name"
                name="FromDisplayName"
                rules={[{ required: true, message: "Please provide a Sender Name" }]}>
                <Input disabled={!isEditingEnabled} />
            </Form.Item>
            <Form.Item
                label="Sender Address"
                name="FromAddress"
                rules={[{ required: true, message: "Please provide a Sender Address" }]}>
                <Input disabled={true} />
            </Form.Item>
            <Form.Item
                label="ReplyTo Name"
                name="ReplyToDisplayName"
                rules={[{ required: true, message: "Please provide a ReplyTo Name" }]}>
                <Input disabled={!isEditingEnabled} />
            </Form.Item>
            <Form.Item
                label="ReplyTo Address"
                name="ReplyToAddress"
                rules={[{ required: true, message: "Please provide a ReplyTo Address" }]}>
                <Input disabled={!isEditingEnabled} />
            </Form.Item>
            <Form.Item
                label="Subject"
                name="Subject"
                rules={[{ required: true, message: "Please provide a Subject" }]}>
                <Input disabled={!isEditingEnabled} />
            </Form.Item>
            <Form.Item
                label={isInvitationReminder ? "Reminder text" : "Body"}
                name="Body"
                valuePropName="initialValue"
                rules={[{ required: true, message: "Please provide a Body" }]}>
                <HtmlEditor
                    disabled={!isEditingEnabled}
                    onValueChanged={onHtmlEditorValueChanged} />
            </Form.Item>
            <Form.Item
                {...buttonsLayout}>
                <ButtonContainer>
                    <Button
                        disabled={!isEditingEnabled}
                        type="primary"
                        htmlType="submit" >
                        Save
                    </Button>
                    <Button
                        disabled={!isEditingEnabled}
                        type="default"
                        onClick={onPreviewClick}>
                        Preview
                    </Button>
                </ButtonContainer>
            </Form.Item>
        </EditorForm>
    )
}