import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { DIContext } from './Dependencies';

import { Layout, Space, Spin } from "antd";
import TemplateEditorContainer from "./Template/TemplateEditorContainer";
import { ViewModel, TemplateCollection } from "./Backend/Models/ViewModel";
import SideMenu from "./Template/SideMenu";

import { Sider, MainLayout, MainPage } from "./App.styles";
import 'antd/dist/antd.css';
import { AdminHeader } from "./Template/Header";
import NewDefaultTemplate from "./Template/NewDefaultTemplate"

export default function Admin({ isoLanguage }: { isoLanguage?: string }): ReactElement {
    const { viewModelEndpoint } = useContext(DIContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [viewModel, setViewModel] = useState<ViewModel>();
    const [selectedTemplates, setSelectedTemplates] = useState<TemplateCollection>();
    const [selectedIsoLanguage, setSelectedIsoLanguage] = useState<string>();

    const history = useHistory();

    useEffect(() => {
        (async () => {
            const result = await viewModelEndpoint.Get();

            setViewModel(result);

            const selectedIsoLanguage = isoLanguage ?? result.templateCollection[0].isoLanguage;
            setSelectedIsoLanguage(selectedIsoLanguage);

            const selectedDefaultTemplates = result.templateCollection.find(x => x.isoLanguage === selectedIsoLanguage);
            setSelectedTemplates(selectedDefaultTemplates);

            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (!viewModel || !selectedIsoLanguage)
            return;

        history.push(`/admin/${selectedIsoLanguage}`);

        const defaultTemplates = viewModel.templateCollection.find(x => x.isoLanguage === selectedIsoLanguage);
        setSelectedTemplates(defaultTemplates)

    }, [viewModel, selectedIsoLanguage]);
    
    function onDefaultTemplatesCreated(templateCollection: TemplateCollection) {
        setViewModel(viewModel => {
            if (!viewModel) return viewModel
            
            const newTemplateCollection = [...viewModel.templateCollection, templateCollection]
                .sort((a, b) => a.isoLanguage.localeCompare(b.isoLanguage))
            return {...viewModel, templateCollection: newTemplateCollection}
        })
        setSelectedIsoLanguage(templateCollection.isoLanguage)
    }

    if (loading)
        return (<Spin size="large" />)

    return (
        <Layout>
            <AdminHeader />
            <Layout>
                <Sider>
                    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                        <NewDefaultTemplate onCreated={onDefaultTemplatesCreated} />
                        <SideMenu
                            selectedKey={selectedIsoLanguage}
                            onSelect={(({ key }) => setSelectedIsoLanguage(key))}
                            items={viewModel!.templateCollection.map(x => ({ key: x.isoLanguage, displayName: x.isoLanguage}))} />
                    </Space>
                </Sider>
                <MainLayout>
                    <MainPage>
                       <TemplateEditorContainer
                            key={selectedIsoLanguage!}
                            templates={selectedTemplates!}
                            welcomeKeywords={viewModel!.welcomeKeywords}
                            invitationKeywords={viewModel!.invitationKeywords}/>
                    </MainPage>
                </MainLayout>
            </Layout>
        </Layout>
    );
}
