import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { DIContext } from './Dependencies';

import { Layout, Spin } from "antd";
import TemplateEditorContainer from "./Template/TemplateEditorContainer";
import { Company } from "./Backend/Models/Company";
import { Panel } from "./Backend/Models/Panel";
import { ViewModel, TemplateCollection } from "./Backend/Models/ViewModel";
import PanelBreadcrumb from "./Template/PanelBreadcrumb";
import SideMenu from "./Template/SideMenu";

import { Sider, MainLayout, MainPage } from "./App.styles";
import 'antd/dist/antd.css';
import { Header } from "./Template/Header";

export default function App({ companyGuid, panelGuid, isoLanguage }: { companyGuid: string, panelGuid?: string, isoLanguage?: string }): ReactElement {
    const { companyEndpoint, viewModelEndpoint, defaultTemplateEndpoint } = useContext(DIContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<Company>();
    const [loadingPanel, setLoadingPanel] = useState<boolean>();
    const [selectedPanel, setSelectedPanel] = useState<Panel>();
    const [selectedIsoLanguage, setSelectedIsoLanguage] = useState<string>();
    const [panelTemplateViewModel, setPanelTemplateViewModel] = useState<ViewModel>();
    const [selectedPanelTemplates, setSelectedPanelTemplates] = useState<TemplateCollection>();

    const history = useHistory();

    useEffect(() => {
        (async () => {
            const result = await companyEndpoint.Get(companyGuid);

            setCompany(result);

            const selectedPanel = panelGuid ? result.panels.find(x => x.guid === panelGuid) : result.panels[0];
            setSelectedPanel(selectedPanel);
        })();
    }, [companyGuid]);

    useEffect(() => {
        if (!selectedPanel || !company)
            return;

        (async () => {
            const result = await viewModelEndpoint.Get(selectedPanel.guid);
            setPanelTemplateViewModel(result);
        })();

        if (selectedPanel.supportedLanguages.includes(isoLanguage!)) {
            setSelectedIsoLanguage(isoLanguage)
        } else {
            setSelectedIsoLanguage(selectedPanel.isoLanguage);
        }
    }, [selectedPanel, company]);

    useEffect(() => {
        setLoadingPanel(true);

        if (!panelTemplateViewModel || !selectedIsoLanguage)
            return;

        history.push(`/${company!.guid}/${selectedPanel!.guid}/${selectedIsoLanguage}`);

        const maybePanelTemplates = panelTemplateViewModel.templateCollection.find(x => x.isoLanguage == selectedIsoLanguage)
            ?? { isoLanguage: selectedIsoLanguage } as TemplateCollection;

        (async () => {
            const panelTemplates = await getDefaultTemplatesForMissingPanelTemplates(maybePanelTemplates);
            setSelectedPanelTemplates(panelTemplates);

            setLoadingPanel(false);
            setLoading(false);
        })();

    }, [panelTemplateViewModel, selectedIsoLanguage]);

    async function getDefaultTemplatesForMissingPanelTemplates(panelTemplates: TemplateCollection): Promise<TemplateCollection> {
        if (!panelTemplates?.welcomeTemplate ||
            !panelTemplates?.welcomeReminderTemplate ||
            !panelTemplates?.invitationTemplate ||
            !panelTemplates?.invitationReminderTemplate) {
            const result = await defaultTemplateEndpoint.Get(panelTemplates.isoLanguage)
            return {
                isoLanguage: panelTemplates.isoLanguage,
                welcomeTemplate: panelTemplates.welcomeTemplate ?? result.welcomeTemplate,
                welcomeReminderTemplate: panelTemplates.welcomeReminderTemplate ?? result.welcomeReminderTemplate,
                invitationTemplate: panelTemplates.invitationTemplate ?? result.invitationTemplate,
                invitationReminderTemplate: panelTemplates.invitationReminderTemplate ?? result.invitationReminderTemplate
            };
        } else {
            return panelTemplates;
        }
    }

    function selectedPanelChanged({ key }: { key: string }): void {
        setLoadingPanel(true);
        const selectedPanel = company!.panels.find(x => x.guid === key)!;
        setSelectedPanel(selectedPanel);
    }

    function onTemplateSave() {
        (async () => {
            const result = await viewModelEndpoint.Get(selectedPanel!.guid);
            setPanelTemplateViewModel(result);
        })();
    }

    if (loading)
        return (<Spin size="large" />)
   
    return (
        <Layout>
            <Header />
            <Layout>
                <Sider>
                    <SideMenu
                        selectedKey={selectedPanel!.guid}
                        onSelect={selectedPanelChanged}
                        items={company!.panels.map(x => ({
                            key: x.guid,
                            displayName: x.name
                        }))} />
                </Sider>
                <MainLayout>
                    <PanelBreadcrumb
                        className="Breadcrumb"
                        panel={selectedPanel!}
                        supportedLanguages={panelTemplateViewModel!.templateCollection.map(x => x.isoLanguage)}
                        isoLanguage={selectedIsoLanguage!}
                        onIsoLanguageChanged={isoLanguage => setSelectedIsoLanguage(isoLanguage)} />
                    <MainPage>
                        {loadingPanel
                            ? <Spin />
                            : <TemplateEditorContainer
                                key={`${selectedPanel!.guid}-${selectedIsoLanguage!}`}
                                panelGuid={selectedPanel!.guid}
                                templates={selectedPanelTemplates!}
                                welcomeKeywords={panelTemplateViewModel!.welcomeKeywords}
                                invitationKeywords={panelTemplateViewModel!.invitationKeywords}
                                onSave={onTemplateSave} />
                        }
                    </MainPage>
                </MainLayout>
            </Layout>
        </Layout>
    );
}
