import React, { ReactElement, useContext } from 'react';
import { Alert as AntdAlert, Button, Layout } from "antd";
import styled from "styled-components";
import { DIContext } from '../Dependencies';

const AntdHeader = Layout.Header;

const StyledHeader = styled(AntdHeader)`
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 10;

    display: flex;
    justify-content: space-between;
`;

const StyledAdminHeader = styled(StyledHeader)`
    background-color: darkorange;
`

const Title = styled.span`
    color: white;
`;

const Alert = styled(AntdAlert)`
    margin: .5em 0 .5em 0
`;

export function Header(): ReactElement {
    const { currentUser, authenticationEndpoint } = useContext(DIContext);

    let adminButton = (<></>)
    if (currentUser.IsAdmin)
        adminButton = <Button size="small" href="/admin" danger>Admin</Button>

    return (
        <StyledHeader>
            <Title>Panelist communications</Title>
            <div>
                {adminButton}
                <Button
                    style={{marginLeft:'1em'}}
                    size='small'
                    href={authenticationEndpoint.LogoutUrl}>
                    Logout
                </Button>
            </div>
        </StyledHeader>)
}

export function AdminHeader(): ReactElement {
    const { authenticationEndpoint } = useContext(DIContext);

    return (
        <StyledAdminHeader>
            <Title>Panelist communications</Title>
            <Alert
                message="Administrative mode"
                type="warning" />
            <div>
                <Button
                    size="small"
                    href="/">Templates
                </Button>
                <Button
                    style={{marginLeft:'1em'}}
                    size='small'
                    href={authenticationEndpoint.LogoutUrl}>
                    Logout
                </Button>
            </div>
        </StyledAdminHeader>
    )
}