import { Layout } from "antd";
import styled from "styled-components";

const AntdSider = Layout.Sider;
const AntdContent = Layout.Content;

export const Sider = styled(AntdSider)`
    overflow: auto;
    height: 100vh;
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
`;

export const MainLayout = styled(Layout)`
    padding-top: 2em;
    padding-right: 2em;
    padding-left: 2em;
`;

export const MainPage = styled(AntdContent)`
    padding: 0.5em 1em 0.5em 1em;
    background-color: white
`;