import React, { ReactElement } from 'react';
import { Modal, Button } from 'antd';

export default function Preview({ content, isModalVisible, toggleModal }: { content: string, isModalVisible: boolean, toggleModal: () => void }): ReactElement {
	return (
		<Modal 
			width={750}
			visible={isModalVisible}
			onOk={toggleModal}
			onCancel={toggleModal} 
			footer={[
				<Button 
					key="ok" 
					type="primary" 
					onClick={toggleModal} >
					OK
				</Button>
				]}	>
			<div dangerouslySetInnerHTML={{__html: content}}></div>
		</Modal>
	);
}
