import { Company } from "../Models/Company";

export class CompanyEndpoint {
    constructor(private baseUrl: string) { }

    public async Get(companyGuid: string): Promise<Company> {
        const response = await fetch(new URL(`company/${companyGuid}`, this.baseUrl).href, { credentials: 'include' })
        return await response.json();
    }
}
