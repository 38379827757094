import { User } from "../Models/User";

export class AuthenticationEndpoint{
    constructor(private baseUrl: string){ }

    public async Authorize(): Promise<User | undefined> {
        const response = await fetch(new URL('authorize', this.baseUrl).href, { credentials: 'include' });
        if (response.status === 200)
            return response.json();
        else if (response.status === 401 || response.status === 403)
            window.location.assign(new URL("login", this.baseUrl).href)
        else if (response.status === -1)
            window.location.assign(new URL("logout", this.baseUrl).href)
        else
            throw new Error("Error trying to login");
    }

    public LogoutUrl: string = new URL('logout', this.baseUrl).href;

}
