import { Context, createContext } from 'react';
import { PanelTemplateEndpoint } from './Backend/Endpoints/PanelTemplateEndpoint';
import { DefaultTemplateEndpoint } from "./Backend/Endpoints/DefaultTemplateEndpoint";
import { AppSettings } from './AppSettings';
import { CompanyEndpoint } from './Backend/Endpoints/CompanyEndpoint';
import { AuthenticationEndpoint } from './Backend/Endpoints/AuthenticationEndpoint';
import { CurrentUser } from './CurrentUser';
import { ViewModelEndpoint } from './Backend/Endpoints/ViewModelEndpoint';
import { User } from './Backend/Models/User';

export const DIContext = createContext<Container|undefined>(undefined) as Context<Container>;

export interface Container {
    authenticationEndpoint: AuthenticationEndpoint;
    viewModelEndpoint: ViewModelEndpoint;
    panelTemplateEndpoint: PanelTemplateEndpoint;
    defaultTemplateEndpoint: DefaultTemplateEndpoint;
    companyEndpoint: CompanyEndpoint;
    currentUser: CurrentUser;
}

export function ConfigureDependencies(appSettings: AppSettings, user: User): Container {
    const container: Container = { 
        authenticationEndpoint: new AuthenticationEndpoint(appSettings.baseUrl),
        viewModelEndpoint: new ViewModelEndpoint(appSettings.baseUrl),
        panelTemplateEndpoint: new PanelTemplateEndpoint(appSettings.baseUrl),
        defaultTemplateEndpoint: new DefaultTemplateEndpoint(appSettings.baseUrl),
        companyEndpoint: new CompanyEndpoint(appSettings.baseUrl),
        currentUser: new CurrentUser(user)
    }
    return container;
}